import Kichiri from "./KichiriService";
import UserService from "./UserService";
import LocationService from "./LocationService";

import { LOCATION_FEATURES } from "../constants/LocationConstants";

export const ReengagementService = {
	/**
	 * Fetch reengagments for a location
	 * @param {Integer} locationId
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Integer} limit
	 */
	async fetch({ locationId, sortField, sortOrder, limit }) {
		try {
			const response = await Kichiri.reengagements.fetch({ locationId }, { sortField, sortOrder, limit }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a signle reengagment
	 * @param {Integer} locationId
	 * @param {Integer} reengagementId
	 */
	async fetchReengagement({ locationId, reengagementId }) {
		try {
			const response = await Kichiri.reengagements.fetchReengagement({ locationId, reengagementId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a reengagment
	 * @param {Integer} locationId
	 * @param {String} name
	 * @param {Integer} templateId
	 * @param {Integer} appointmentLookbackDays
	 * @param {Integer} lastAppointmentDays
	 * @param {Integer} lastMessageDays
	 * @param {Integer} maxAnnualReengagements
	 * @param {Integer} matchUpcomingAppointments
	 * @param {Integer} delayHours
	 * @param {Array/String} types
	 * @param {Boolean} typesExactMatch
	 * @param {Array/String} assignedReps
	 * @param {Boolean} assignedRepsExactMatch
	 * @param {Array/String} apptStates
	 * @param {Array} conditions
	 * @param {String} status
	 *
	 * @returns {Object} Reengagement
	 */
	async create({
		locationId,
		name,
		templateId,
		appointmentLookbackDays,
		lastAppointmentDays,
		lastMessageDays,
		maxAnnualReengagements,
		matchUpcomingAppointments,
		delayHours,
		types,
		typesExactMatch,
		assignedReps,
		assignedRepsExactMatch,
		apptStates,
		conditions,
		status
	}) {
		try {
			let response = await Kichiri.reengagements.create(
				{
					locationId,
					name,
					templateId,
					appointmentLookbackDays,
					lastAppointmentDays,
					lastMessageDays,
					maxAnnualReengagements,
					matchUpcomingAppointments,
					delayHours,
					types,
					typesExactMatch,
					assignedReps,
					assignedRepsExactMatch,
					apptStates,
					conditions,
					status
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a reengagment
	 * @param {Integer} locationId
	 * @param {Integer} reengagementId
	 * @param {String} name
	 * @param {Integer} templateId
	 * @param {Integer} appointmentLookbackDays
	 * @param {Integer} lastAppointmentDays
	 * @param {Integer} lastMessageDays
	 * @param {Integer} maxAnnualReengagements
	 * @param {Integer} matchUpcomingAppointments
	 * @param {Integer} delayHours
	 * @param {Array/String} types
	 * @param {Boolean} typesExactMatch
	 * @param {Array/String} assignedReps
	 * @param {Boolean} assignedRepsExactMatch
	 * @param {Array/String} apptStates
	 * @param {Array} conditions
	 * @param {String} status
	 *
	 * @returns {Object} Reengagement
	 */
	async update({
		locationId,
		reengagementId,
		templateId,
		name,
		appointmentLookbackDays,
		lastAppointmentDays,
		lastMessageDays,
		maxAnnualReengagements,
		matchUpcomingAppointments,
		delayHours,
		types,
		typesExactMatch,
		assignedReps,
		assignedRepsExactMatch,
		apptStates,
		conditions,
		status
	}) {
		try {
			let response = await Kichiri.reengagements.update(
				{
					locationId,
					reengagementId,
					name,
					templateId,
					appointmentLookbackDays,
					lastAppointmentDays,
					lastMessageDays,
					maxAnnualReengagements,
					matchUpcomingAppointments,
					delayHours,
					types,
					typesExactMatch,
					assignedReps,
					assignedRepsExactMatch,
					apptStates,
					conditions,
					status
				},
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch config
	 * @param {Integer} locationId
	 */
	async fetchConfig({ locationId }) {
		try {
			const response = await Kichiri.reengagements.fetchConfig({ locationId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return;
	},

	/**
	 * Check if the user is able to view reengagments
	 * @returns {Boolean}
	 */
	canViewReenagements() {
		try {
			const user = UserService.get();
			return user.GroupPermission.view_reengagements && this.isReengagementEnabled();
		} catch (error) {
			console.log(error);
			return false;
		}
	},

	/**
	 * Check if reengagments is enabled for the location
	 * @returns {Boolean}
	 */
	isReengagementEnabled() {
		try {
			return LocationService.isLocationFeaturePermissible(LOCATION_FEATURES.messenger_scheduled_reengagement.id);
		} catch (error) {
			console.log(error);
			return false;
		}
	}
};

export default ReengagementService;
