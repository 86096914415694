import React from "react";
import * as Icon from "react-feather";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import withLocation from "../../../components/common/WithLocation";

import ReengagementService from "../../../services/ReengagementService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";

import Header from "../../../components/common/Header";
import List from "../../../components/common/List";
import Page from "../../../components/common/Page";
import Action from "../../../components/common/Action";
import Alert from "../../../components/common/Alert";
import ReengagementManageModal from "./ReengagementManageModal";
import ReengagementSettingsModal from "./ReengagementSettingsModal";

import { SORT_ORDER, STATUS, STATUS_LABEL } from "../../../constants/CommonConstants";
import { REENGAGEMENTS_COLUMNS } from "../../../constants/Settings";

class Reengagements extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			showManageModal: false,
			showReengagementSettingsModal: false,
			selectedReengagementId: null,
			selectedReengagementStatus: null,

			loading: false,
			loadedAll: false,
			sortField: REENGAGEMENTS_COLUMNS.name.id,
			sortOrder: SORT_ORDER.asc,
			limit: 50,

			showDeleteAlert: false,
			showToggleStatusAlert: false
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	onLocationChanged = () => {
		this.resetComponent();
	};

	resetComponent = async () => {
		this.fetchData();
	};

	fetchData = async () => {
		let { t } = this.props;

		await this.update({ loading: true });

		let { sortField, sortOrder, limit } = this.state;

		let data = await ReengagementService.fetch({ locationId: UserService.getActiveLocation().id, sortField, sortOrder, limit });

		this.update({ loading: false });

		if (!data) {
			ToastService.error(t("Error getting reengagements"));
			return;
		}

		this.update({ data, loadedAll: data.length < limit });
	};

	onShowManageModal = async id => {
		if (id) {
			await this.update({ selectedReengagementId: id, showManageModal: true });
			return;
		}
		await this.update({ showManageModal: true, selectedReengagementId: null });
	};

	onHideManageModal = resetComponent => {
		this.update({ showManageModal: false, selectedReengagementId: null });

		if (resetComponent) {
			this.resetComponent();
		}
	};

	onOpenReengagementSettings = async id => {
		await this.update({ selectedReengagementId: id, showReengagementSettingsModal: true });
	};

	onHideReengagementSettingsModal = async resetComponent => {
		await this.update({ selectedReengagementId: null, showReengagementSettingsModal: false });

		if (resetComponent) {
			this.resetComponent();
		}
	};

	openDeleteAlert = (id = null) => {
		this.update({
			showDeleteAlert: true,
			selectedReengagementId: id
		});
	};

	onConfirmDelete = async confirm => {
		let { t } = this.props;

		this.update({
			showDeleteAlert: false,
			selectedReengagementId: null
		});

		if (!confirm) {
			return;
		}

		let response = await ReengagementService.update({
			locationId: UserService.getActiveLocation().id,
			reengagementId: this.state.selectedReengagementId,
			status: STATUS.deleted
		});

		if (!response) {
			ToastService.error(t("Error updating Reengagememt. Please try again."));
			return;
		}

		ToastService.info(t("Deleted Rengagement!"));

		this.resetComponent();
	};

	openToggleStatusAlert = (id = null, status) => {
		this.update({
			showToggleStatusAlert: true,
			selectedReengagementId: id,
			selectedReengagementStatus: status
		});
	};

	onConfirmDeactivate = async confirm => {
		let { t } = this.props;

		let status = this.state.selectedReengagementStatus;
		let reengagementId = this.state.selectedReengagementId;

		await this.update({
			showToggleStatusAlert: false,
			selectedReengagementId: null,
			selectedReengagementStatus: null
		});

		if (!confirm) {
			return;
		}

		let response = await ReengagementService.update({
			locationId: UserService.getActiveLocation().id,
			reengagementId: reengagementId,
			status: status === STATUS.active ? STATUS.inactive : STATUS.active
		});

		if (!response) {
			ToastService.error(t("Error updating Reengagememt. Please try again."));
			return;
		}

		ToastService.info(t("Status updated!"));

		this.resetComponent();
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;

		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;

		await this.update({ sortField, sortOrder });
		await this.fetchData();
	};

	getHeaders() {
		let headers = {
			items: REENGAGEMENTS_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	}

	renderRecord = recordData => {
		let { t } = this.props;

		try {
			return [
				recordData.name,
				recordData.appointment_look_back_days,
				recordData.contact_last_appointment_days,
				recordData.max_annual_reengagements,
				recordData.last_eligible_reengagements,
				<div className="dh-list__actions">
					{UserService.get().GroupPermission.update_reengagements && (
						<Action transparent id={`edit-${recordData.id}`} label={t("Edit")} icon={Icon.Edit} onClick={() => this.onShowManageModal(recordData.id)} />
					)}
					{UserService.get().GroupPermission.delete_reengagements && (
						<Action transparent id={`delete-${recordData.id}`} label={t("Delete")} icon={Icon.Trash} onClick={() => this.openDeleteAlert(recordData.id)} />
					)}
					{UserService.get().GroupPermission.update_reengagements && (
						<Action
							transparent
							id={`status-${recordData.id}`}
							label={recordData.status === STATUS.active ? t("Deactivate") : t("Activate")}
							icon={recordData.status === STATUS.active ? Icon.Power : Icon.Zap}
							onClick={() => this.openToggleStatusAlert(recordData.id, recordData.status)}
						/>
					)}
				</div>
			];
		} catch (error) {
			console.log(error);
			return null;
		}
	};

	render = () => {
		const {
			data,
			loading,
			loadedAll,
			sortField,
			sortOrder,
			showManageModal,
			showReengagementSettingsModal,
			selectedReengagementId,
			showDeleteAlert,
			showToggleStatusAlert,
			selectedReengagementStatus
		} = this.state;
		let { t } = this.props;

		let user = UserService.get();

		if (!ReengagementService.canViewReenagements()) {
			return <Redirect to="/settings" />;
		}

		return (
			<Page>
				<Header title="Reengagements">
					{user.GroupPermission.update_locations && (
						<Action id="settings" label={t("Reengagement Settings")} icon={Icon.Settings} onClick={() => this.onOpenReengagementSettings()} />
					)}
					{user.GroupPermission.create_reengagements && (
						<Action id="create" label={t("Create Reengagement")} icon={Icon.Plus} onClick={() => this.onShowManageModal()} />
					)}
				</Header>

				<List
					items={data}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={() => {}}
					noDataTitle={t("No Reengagement Settings found...")}
					noDataIcon={<Icon.AlertCircle />}
				/>

				<ReengagementManageModal show={showManageModal} onHide={this.onHideManageModal} id={selectedReengagementId} />
				<ReengagementSettingsModal show={showReengagementSettingsModal} onHide={this.onHideReengagementSettingsModal} id={selectedReengagementId} />

				<Alert show={showDeleteAlert} onClose={this.onConfirmDelete} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")}>
					{t("Are you sure you would like to delete this reengagement?")}
				</Alert>

				<Alert show={showToggleStatusAlert} onClose={this.onConfirmDeactivate} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")}>
					{selectedReengagementStatus === STATUS.active
						? t("Are you sure you would like to deactivate this reengagement?`")
						: t("Are you sure you would like to activate this reengagement?`")}
				</Alert>
			</Page>
		);
	};
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(Reengagements)));
