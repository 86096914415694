import React from "react";
import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import queryString from "query-string";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";
import { getServices } from "service-fetch";

import GAService from "../../../services/GAService";
import LocationService from "../../../services/LocationService";
import ToastService from "../../../services/ToastService";
import UserService from "../../../services/UserService";
import UtilityService from "../../../services/UtilityService";

import Page from "../../../components/common/Page";
import Header from "../../../components/common/Header";
import Select from "../../../components/common/DHSelect";
import Input from "../../../components/common/Input";
import Checkbox from "../../../components/common/Checkbox";
import AddressAutocomplete from "../../../components/common/AddressAutocomplete";
import DHCard from "../../../components/common/DHCard";
import DHCardHeader from "../../../components/common/DHCardHeader";

import {
	CONTRACT_TYPE_OPTIONS,
	CURRENCY_OPTIONS,
	FREQUENCY_OPTIONS,
	POORLY_MANAGED_COUPONS,
	SIGN_UP_STATE_FILTERS,
	FEATURE_LIST
} from "../../../constants/SignUpConstants";

import "./sign-ups.css";

const { Sign_upsService: SignUpService } = getServices();

class ManageSignUps extends React.Component {
	constructor(props) {
		super(props);
		let { searchTerm, sortField, sortOrder, dateRangeStart, dateRangeEnd, stateFilter } = queryString.parse(this.props.location.search);

		let user = UserService.get();

		let numberOfTrialDays = 14;

		let userName = UserService.createFullName({ firstName: user.first_name, lastName: user.last_name });

		this.state = {
			loading: true,

			signUpId: this.props.match.params.signUpId,
			state: SIGN_UP_STATE_FILTERS.draft.id,

			planOptions: [],
			couponOptions: [],

			businessName: "",
			googlePlaceId: "",
			googlePlaceCid: "",
			contactName: "",
			contactPhone: "",
			contactEmail: "",
			userName,
			userPhone: user.phone || "",
			userEmail: user.email,

			autoCompleteAddressObject: { label: "", value: "" },
			address1: "",
			address2: "",
			city: "",
			stateProvince: "",
			zipPostal: "",
			country: "",
			latitude: "",
			longitude: "",
			timezoneId: null,

			pricingPlan: "",
			promos: "",
			currency: "",
			frequency: "",
			startDate: moment().format("YYYY-MM-DD"),
			numberOfTrialDays,
			billingDate: moment().format("YYYY-MM-DD"),
			contractType: "",
			isFreeTrial: false,
			ignorePayment: false,
			specialTerms: "",

			featureList: []
		};
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	resetComponent = async () => {
		await this.fetchData();
	};

	fetchData = async () => {
		let { signUpId } = this.state;

		await this.update({
			loading: true
		});

		await this.fetchChargebeeDetails();

		if (this.isCreateMode()) {
			await this.update({
				loading: false
			});
			return;
		}

		let { data: signUp } = await SignUpService.fetchSignUp({ params: { signUpId } });

		if (!signUp) {
			ToastService.error("Error getting sign up. Please try again.");
			await this.update({
				loading: false
			});
			return null;
		}

		await this.fetchChargebeeDetails({ currency: signUp.currency });

		let { couponOptions, planOptions } = this.state;

		let locationFields = signUp.location_fields;
		let pricingPlan = planOptions.find(option => option.value === signUp.charge_bee_plan_id);
		let promos = this.getSelectedPromos(signUp);
		let currency = CURRENCY_OPTIONS.find(option => option.value === signUp.currency);
		let frequency = FREQUENCY_OPTIONS.find(option => option.value === signUp.billing_frequency);
		let contractType = CONTRACT_TYPE_OPTIONS.find(option => option.value === signUp.contract_type);
		let featureList = signUp.features || [];

		let user = UserService.get();
		let userName = UserService.createFullName({ firstName: user.first_name, lastName: user.last_name });

		await this.update({
			loading: false,
			signUpId: signUp.id,
			state: signUp.state,

			businessName: signUp.legal_business_name,
			googlePlaceId: signUp.google_place_id,
			googlePlaceCid: locationFields.googlePlaceCid || "",
			contactName: signUp.contact_name,
			contactPhone: signUp.contact_phone,
			contactEmail: signUp.contact_email,

			userName: signUp.ae_user_name || userName,
			userPhone: signUp.ae_user_phone || user.phone,
			userEmail: signUp.ae_user_email || user.email,

			autoCompleteAddressObject: { label: "", value: "" },
			address1: locationFields.address_1,
			address2: locationFields.address_2,
			city: locationFields.city,
			stateProvince: locationFields.state_prov,
			zipPostal: locationFields.zip_postal,
			country: locationFields.country,
			latitude: locationFields.latitude,
			longitude: locationFields.longitude,
			timezoneId: locationFields.timezoneId,

			pricingPlan,
			promos,
			currency,
			frequency,
			contractType,
			startDate: signUp.start_date,
			billingDate: signUp.billing_date,
			isFreeTrial: signUp.is_free_trial,
			ignorePayment: signUp.ignore_payment,
			specialTerms: signUp.special_terms,

			featureList
		});
	};

	getSelectedPromos = signUp => {
		let { couponOptions } = this.state;
		let promos = null;

		try {
			if (!signUp.charge_bee_promo_codes || signUp.charge_bee_promo_codes.length < 1) {
				return promos;
			}

			promos = [];
			// For all the available coupons, get the coupons that are on the sign up
			for (let i = 0; i < couponOptions.length; i++) {
				const option = couponOptions[i];
				// If the coupon option is in the list of coupons on this sign up
				if (signUp.charge_bee_promo_codes.includes(option.value)) {
					promos.push(option);
				}
			}
		} catch (error) {
			console.log(error);
		}
		return promos;
	};

	fetchChargebeeDetails = async ({ planId, currency, frequency } = {}) => {
		let response = await SignUpService.fetchChargebeeDetails({
			query: {
				planId,
				currency,
				frequency
			}
		});
		let { data } = response;

		if (!data) {
			ToastService.error("Error fetching plans.");
			return;
		}

		let planOptions = data.plans.map(plan => {
			return {
				value: plan.id,
				label: plan.name
			};
		});

		let filteredCoupons = data.coupons.filter(coupon => {
			return POORLY_MANAGED_COUPONS.includes(coupon.name);
		});

		let couponOptions = filteredCoupons.map(coupon => {
			return {
				value: coupon.id,
				label: coupon.name
			};
		});

		await this.update({
			planOptions,
			couponOptions
		});

		return data;
	};

	isCreateMode() {
		const { signUpId } = this.state;

		return signUpId === "create";
	}

	handleToggleField(field, value) {
		// If the free trial is being modified and is being enabled, then we automatically set the bililling date as well
		if (field === "isFreeTrial" && value) {
			let { startDate, numberOfTrialDays } = this.state;
			let billingDate = moment(startDate)
				.add(numberOfTrialDays, "days")
				.format("YYYY-MM-DD");
			this.update({ billingDate });
		} else if (field === "isFreeTrial" && !value) {
			// If the free trial is being modified and is being disabled, then we automatically set the bililling date as well

			let { startDate } = this.state;
			let billingDate = moment(startDate).format("YYYY-MM-DD");
			this.update({ billingDate });
		}

		this.update({
			[field]: value
		});
	}

	onPrefill = async () => {
		let { autoCompleteAddressObject } = this.state;

		let data = await LocationService.getPlacesDetails(autoCompleteAddressObject.value);

		const { name, administrative_area_level_1, cid, country, locality, phone, postal_code, street_address, website, subpremise, complete_address } = data;

		this.update({
			businessName: name,
			address2: subpremise,
			address1: street_address,
			city: locality,
			stateProvince: administrative_area_level_1,
			country,
			countryObject: { label: country, value: country },
			latitude: data.latitude,
			longitude: data.longitude,
			timezoneId: data.timezoneId || null,
			zipPostal: postal_code,
			website,
			phone,
			googlePlaceCid: cid,
			googlePlaceId: autoCompleteAddressObject.value,
			completeAddress: complete_address,

			changesMade: true
		});
	};

	handleAutocompleteAddressOnChange = async autoCompleteAddressObject => {
		this.update({
			autoCompleteAddressObject
		});
	};

	handleGenericEventHandler = event => {
		let name = event.target.name;
		let value = event.target.value;

		let { isFreeTrial, startDate, billingDate, numberOfTrialDays } = this.state;

		if (name === "startDate" && !isFreeTrial) {
			billingDate = value;
			this.update({ billingDate });
		} else if (name === "startDate" && isFreeTrial) {
			billingDate = moment(value)
				.add(numberOfTrialDays, "days")
				.format("YYYY-MM-DD");

			this.update({ billingDate });
		}

		if (name === "billingDate" && isFreeTrial) {
			numberOfTrialDays = moment(value).diff(moment(startDate), "days");
			this.update({ numberOfTrialDays });
		}

		if (name === "numberOfTrialDays" && value) {
			billingDate = moment(startDate)
				.add(value, "days")
				.format("YYYY-MM-DD");

			this.update({ billingDate });
		}

		this.update({ [name]: value });
	};

	isFormValid() {
		let {
			businessName,

			// Contact information
			contactName,
			contactPhone,
			contactEmail,
			userPhone,

			// Address and location information
			address1,
			city,
			stateProvince,
			country,
			zipPostal,
			phone,

			// Billing and contract information
			pricingPlan,
			currency,
			frequency,
			startDate,
			contractType
		} = this.state;

		if (!businessName) {
			ToastService.error("Business name required.");
			return false;
		}

		if (!contactName) {
			ToastService.error("Contact name required.");
			return false;
		}

		if (!address1 || !city || !stateProvince || !country || !zipPostal) {
			ToastService.error("Business address fields required.");
			return false;
		}

		if (phone && !UtilityService.isMobilePhoneValid(phone)) {
			ToastService.error("Business phone is invalid.");
			return false;
		}

		if (!contactEmail || !UtilityService.isEmail(contactEmail)) {
			ToastService.error("Contact email is invalid.");
			return false;
		}

		if (!contactPhone || !UtilityService.isMobilePhoneValid(contactPhone)) {
			ToastService.error("Contact phone is invalid.");
			return false;
		}

		if (!userPhone || !UtilityService.isMobilePhoneValid(userPhone)) {
			ToastService.error("User phone is invalid.");
			return false;
		}

		if (!pricingPlan || !pricingPlan.value) {
			ToastService.error("Select a Pricing Plan.");
			return;
		}

		if (!contractType || !contractType.value) {
			ToastService.error("Select a Contract Type.");
			return;
		}

		if (!frequency || !frequency.value) {
			ToastService.error("Select a Frequency.");
			return;
		}
		if (!currency || !currency.value) {
			ToastService.error("Select a Currency.");
			return;
		}
		if (!startDate) {
			ToastService.error("Start Date name required.");
			return false;
		}
		return true;
	}

	onSubmit = async event => {
		event.preventDefault();

		let isValid = this.isFormValid();

		if (!isValid) {
			return;
		}

		let {
			signUpId,
			businessName,
			googlePlaceId,
			googlePlaceCid,

			// Contact information
			contactName,
			contactPhone,
			contactEmail,

			// User information
			userName,
			userPhone,
			userEmail,

			// Address and location information
			address2,
			address1,
			city,
			stateProvince,
			country,
			latitude,
			longitude,
			timezoneId,
			zipPostal,
			website,
			phone,

			// Billing and contract information
			pricingPlan,
			promos,
			currency,
			frequency,
			startDate,
			billingDate,
			contractType,
			isFreeTrial,
			ignorePayment,
			specialTerms,

			featureList
		} = this.state;

		if (!pricingPlan || !pricingPlan.value) {
			ToastService.error(`Missing Pricing Plan`);
			return;
		}

		let { planVariant } = await this.fetchChargebeeDetails({ planId: pricingPlan.value, currency: currency.value, frequency: frequency.value });

		if (!planVariant) {
			ToastService.error(`Selected plan with currency '${currency.value}' and frequency '${frequency.value}' does not exist.`);
			return;
		}

		let body = {
			signUpId,
			businessName,
			googlePlaceId,
			googlePlaceCid,

			// Contact information
			contactName,
			contactPhone: contactPhone,
			contactEmail: contactEmail,

			// User information
			userName,
			userPhone: userPhone,
			userEmail,

			// Address and location information
			address2: address2 || "",
			address1,
			city,
			stateProvince,
			country,
			latitude,
			longitude,
			timezoneId,
			zipPostal,
			website: website || "",
			phone: phone ? UtilityService.isMobilePhoneValid(phone) : "",

			// Billing and contract information
			pricingPlan: pricingPlan.value,
			promos: promos ? promos.map(p => p.value) : null,
			currency: currency ? currency.value : null,
			frequency: frequency ? frequency.value : null,
			startDate: moment(startDate).format("YYYY-MM-DD"),
			billingDate: moment(billingDate).format("YYYY-MM-DD"),
			contractType: contractType.value,
			isFreeTrial,
			ignorePayment,
			specialTerms,

			features: featureList
		};

		if (this.isCreateMode()) {
			let { error } = await SignUpService.createSignUp({
				body
			});

			/**
			 * Capture error
			 */

			if (error) {
				ToastService.error("Error creating Sign Up.");
				return;
			}

			ToastService.info("Sign up created!");

			await UtilityService.timeout(1500);

			this.props.history.push("/customer-success/sign-ups");

			return;
		}

		let { error } = await SignUpService.updateSignUp({
			params: {
				signUpId
			},
			body
		});

		if (error) {
			ToastService.error("Error updating Sign Up.");
			return;
		}

		ToastService.info(`${businessName} updated!`);

		this.props.history.push("/customer-success/sign-ups");
	};

	renderForm = () => {
		let {
			planOptions,
			couponOptions,

			autoCompleteAddressObject,
			businessName,
			googlePlaceId,
			contactName,
			contactPhone,
			contactEmail,
			userName,
			userPhone,
			userEmail,
			address1,
			address2,
			city,
			stateProvince,
			zipPostal,
			country,

			pricingPlan,
			promos,
			currency,
			frequency,
			startDate,
			billingDate,
			numberOfTrialDays,
			contractType,
			isFreeTrial,
			ignorePayment,
			specialTerms,

			featureList
		} = this.state;
		let canCreate = UserService.isSuperAdminOrCustomerSuccessOrAccountOwner();

		return (
			<form onSubmit={this.onSubmit}>
				<div className="msu__form">
					<div className="input msu__form__auto-complete">
						<div className="msu__form__auto-complete__input">
							<div className="input__label" data-tip data-for="date-range-start-date-rtt">
								Find Business on Google <Icon.Info size={13} />
								<ReactTooltip id="date-range-start-date-rtt" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
									Helps connect Google accounts to the location setup. It collects googe place id, location timezone, and other fields.
								</ReactTooltip>
							</div>
							<AddressAutocomplete id="address_auto_complete" value={autoCompleteAddressObject} onChange={this.handleAutocompleteAddressOnChange} />
						</div>
						<div className="mb-button mb-button--fit msu__form__auto-complete__button" onClick={this.onPrefill}>
							Prefill
						</div>
					</div>

					<DHCard>
						<DHCardHeader>Business Details</DHCardHeader>
						<Input
							id="business-name"
							name="businessName"
							label={"Legal Business Name"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={businessName}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="address-1"
							name="address1"
							label={"Address 1"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={address1}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="address-2"
							name="address2"
							label={"Address 2"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={address2}
							autoComplete="off"
							required={false}
						/>
						<Input id="city" name="city" label={"City"} type="text" onChange={this.handleGenericEventHandler} value={city} autoComplete="off" required={true} />
						<Input
							id="state-province"
							name="stateProvince"
							label={"State Province"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={stateProvince}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="zip-postal"
							name="zipPostal"
							label={"Zip Postal"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={zipPostal}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="country"
							name="country"
							label={"Country"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={country}
							autoComplete="off"
							required={true}
						/>
					</DHCard>

					<DHCard>
						<DHCardHeader>Contact Details</DHCardHeader>
						<Input
							id="contact-name"
							name="contactName"
							label={"Contact Name"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={contactName}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="contact-phone"
							name="contactPhone"
							label={"Contact Phone"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={contactPhone}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="contact-email"
							name="contactEmail"
							label={"Contact Email"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={contactEmail}
							autoComplete="off"
							required={true}
						/>
					</DHCard>

					<DHCard>
						<DHCardHeader>Account Executive Details</DHCardHeader>
						<Input
							id="user-name"
							name="userName"
							label={"User Name"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={userName}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="user-phone"
							name="userPhone"
							label={"User Phone"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={userPhone}
							autoComplete="off"
							required={true}
						/>
						<Input
							id="user-email"
							name="userEmail"
							label={"User Email"}
							type="text"
							onChange={this.handleGenericEventHandler}
							value={userEmail}
							autoComplete="off"
							required={true}
						/>
					</DHCard>

					<DHCard>
						<DHCardHeader>Subscription Details</DHCardHeader>

						<div className="input">
							<div className="input__label">
								Currency<span className="input__label__required">*</span>
							</div>
							<Select
								className=""
								value={currency}
								placeholder="Select Currency"
								options={CURRENCY_OPTIONS}
								onChange={currency => {
									this.update({ currency: currency });
									this.fetchChargebeeDetails({ currency: currency.value, frequency: frequency.value });
								}}
								required={true}
							/>
						</div>

						<div className="input">
							<div className="input__label">
								Frequency <span className="input__label__required">*</span>
							</div>
							<Select
								className=""
								value={frequency}
								placeholder="Select Frequency"
								options={FREQUENCY_OPTIONS}
								onChange={frequency => {
									this.update({ frequency: frequency });
									this.fetchChargebeeDetails({ currency: currency.value, frequency: frequency.value });
								}}
								required={true}
							/>
						</div>

						<div className="input">
							<div className="input__label">
								Pricing Plan <span className="input__label__required">*</span>
							</div>
							<Select
								className=""
								value={pricingPlan}
								placeholder="Select Pricing Plan"
								options={planOptions}
								onChange={value => this.update({ pricingPlan: value })}
								required={true}
							/>
						</div>

						<div className="input">
							<div className="input__label">Promotions</div>
							<Select
								// className="msu__form__input__select"
								value={promos}
								placeholder="Select Promos"
								options={couponOptions}
								onChange={value => {
									this.update({ promos: value });
								}}
								isMulti={true}
								required={false}
							/>
						</div>

						<div className="input">
							<div className="input__label">
								Contract Type <span className="input__label__required">*</span>
							</div>
							<Select
								className=""
								value={contractType}
								placeholder="Select Contract Type"
								options={CONTRACT_TYPE_OPTIONS}
								onChange={value => this.update({ contractType: value })}
								required={true}
							/>
						</div>

						<Select
							label="Features"
							required
							// className="msu__form__input__select"
							value={featureList}
							placeholder="Select Features"
							options={FEATURE_LIST}
							onChange={value => {
								this.update({ featureList: value });
							}}
							isMulti={true}
						/>

						<div>Billing will start at 5pm EST</div>
						<br />
						<div className="input">
							<div className="input__label">
								Billing Frequency <span className="input__label__required">*</span>
							</div>
							<Select
								className=""
								value={frequency}
								placeholder="Select Frequency"
								options={FREQUENCY_OPTIONS}
								onChange={value => this.update({ frequency: value })}
								required={true}
							/>
						</div>

						<div className="input msu__form__input__checkbox">
							<div className="input__label">Is Free Trial</div>

							<Checkbox
								id="ignore-payment"
								name="isFreeTrial"
								checked={isFreeTrial}
								onChange={() => this.handleToggleField("isFreeTrial", !isFreeTrial)}
								className=""
								// radio={true}
							/>
						</div>
						<br />

						<Input
							id="start-date"
							name="startDate"
							label={"Start Date"}
							type="date"
							onChange={this.handleGenericEventHandler}
							value={startDate}
							autoComplete="off"
							required={true}
						/>

						{isFreeTrial && (
							<Input
								id="trial-days"
								name="numberOfTrialDays"
								label={"Days in Trial"}
								type="number"
								onChange={this.handleGenericEventHandler}
								value={numberOfTrialDays}
								autoComplete="off"
								required={true}
							/>
						)}

						{isFreeTrial && (
							<Input
								id="billing-date"
								name="billingDate"
								label={"Billing Date"}
								type="date"
								onChange={this.handleGenericEventHandler}
								value={billingDate}
								autoComplete="off"
								required={true}
							/>
						)}

						<div className="input msu__form__input__checkbox">
							<div className="input__label">Ignore Payment Field</div>

							<Checkbox
								id="ignore-payment"
								name="ignorePayment"
								checked={ignorePayment}
								onChange={() => this.handleToggleField("ignorePayment", !ignorePayment)}
								className=""
							/>
						</div>
						<br />

						<Input
							id="special-terms"
							name="specialTerms"
							label={"Special Terms"}
							type="textarea"
							onChange={this.handleGenericEventHandler}
							value={specialTerms}
							autoComplete="off"
							required={false}
						/>
					</DHCard>

					{canCreate && this.renderActions()}
				</div>
			</form>
		);
	};

	renderActions = () => {
		return (
			<div className="msu__form__buttons">
				<button className="mb-button mb-button--fit mb-button--right" type="submit">
					Save
				</button>
			</div>
		);
	};

	renderLoading = () => {
		return (
			<div className="msu__form">
				<ContentLoader viewBox="0 0 100% 956" height={956} width={"100%"}>
					{/* Google Search Input */}
					<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />

					{/* Business details section */}
					<rect x="0" y="75" rx="5" ry="5" width="100%" height="568" />

					{/* Contact details section */}
					<rect x="0" y="668" rx="5" ry="5" width="100%" height="268" />
				</ContentLoader>
			</div>
		);
	};

	render = () => {
		let { state, loading } = this.state;

		return (
			<Page>
				<Header title={`${this.isCreateMode() ? "Create" : "Update"} Sign Up`} onBackButtonClick={() => this.props.history.goBack()}>
					<div className="msu-state">
						{loading ? <div className="msu-state__text">Loading...</div> : <div className="msu-state__text">{this.isCreateMode() ? "draft" : state}</div>}
					</div>
				</Header>
				{loading && this.renderLoading()}
				{!loading && <div className="msu">{this.renderForm()}</div>}
			</Page>
		);
	};
}

export default withRouter(ManageSignUps);
