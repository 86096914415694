import moment from "moment";
import franc from "franc-min";
import uuidv4 from "uuid/v4";
import uuidv1 from "uuid/v1";

// TODO: DH-3126
// import sunCalc from "suncalc";
import isMobilePhone from "validator/lib/isMobilePhone";
import { linkify } from "react-linkify";
import libphonenumber from "google-libphonenumber";

import AppConfig from "../config/app/web-app.config.js";
import UserService from "./UserService";
import ToastService from "./ToastService.js";

import { LANGUAGE_DIRECTION, MEDIA_FILE_TYPES, MEDIA_TYPES } from "../constants/Messenger";
import { DATE_RANGES, DATE_FORMAT, MERIDIAN_TIMES_OPTIONS } from "../constants/CommonConstants";
import { PASSWORD_REQUIREMENTS_GENERATOR, USER_PASSWORD_REQUIREMENTS } from "../constants/Password.js";

const YOUTUBE_REGEX = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;

let navigationCollapsed = false;

const DEFAULT_SUNRISE_HOUR = 6; // 6 am
const DEFAULT_SUNSET_HOUR = 18; // 6 pm

let cachedData = {};

// Using the internal linkify-it instance within react-linkify to add a scheme for Team Chat Mentions
linkify.add("@", {
	validate: function(text, pos, self) {
		// Find the first space after the index (pos) in the text
		for (let i = pos; i < text.length; i++) {
			if (text[i] === " ") {
				// return the length of the mention
				return i - pos;
			}
		}

		// If we never hit a space, it must mean we are at the end of the string
		return text.length - pos;
	},
	normalize: function(match) {
		// Since we don't support jumping to conversations by clicking the mention, we can have this js void for now
		// eslint-disable-next-line
		match.url = "javascript:void(0);";
	}
});

const UtilityService = {
	/**
	 * Indicates whether or not the current browser is Safari (bloody blastards)
	 *
	 * @return {Boolean}
	 */
	isSafari() {
		return (
			/constructor/i.test(window.HTMLElement) ||
			(function(p) {
				return p.toString() === "[object SafariRemoteNotification]";
			})(!window["safari"] || (typeof safari !== "undefined" && window["safari"].pushNotification))
		);
	},

	/**
	 * Simple Timeout promise
	 *
	 * @param {Number} ms
	 * @return {Promise}
	 */
	timeout: function(ms) {
		return new Promise(function(resolve, reject) {
			setTimeout(resolve, ms);
		});
	},
	/**
	 * This function returns a boolean value indicating whether
	 * the current viewing media is a mobile device or not
	 */
	mobileCheck() {
		var check = false;
		(function(a) {
			if (
				/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
					a
				) ||
				/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r|s)|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
					a.substr(0, 4)
				)
			)
				check = true;
		})(navigator.userAgent || navigator.vendor || window.opera);
		return check;
	},

	/**
	 * Appends Basic Auth parameters to a url
	 *
	 * @param {String} authToken
	 * @param {String} url
	 * @return {String}
	 */
	appendBasicAuth(authToken, url) {
		if (url.indexOf("https://") !== -1) {
			url = `https://demandhub:${authToken}@` + url.slice(8);
		} else if (url.indexOf("http://") !== -1) {
			url = `http://demandhub:${authToken}@` + url.slice(7);
		}

		return url;
	},

	/**
	 * Retrieves the extension of the file in an URL format
	 *
	 * @return {String}
	 */
	getExtensionFromUrl(url) {
		url = this.getPathFromUrl(url);
		url = url.substring(url.lastIndexOf(".") + 1, url.lastIndexOf("?") !== -1 ? url.lastIndexOf("?") : url.length).toLowerCase();
		return url;
	},

	/**
	 * Appends auth token to as a query param
	 *
	 * @param  {String} authToken
	 * @return {String}
	 */
	appendQueryAuthToken(url) {
		try {
			let locationId = UserService.getActiveLocation().id;
			let authToken = UserService.get().auth_token;

			if (url.indexOf(AppConfig.API_SERVER) === -1) {
				return url;
			}

			url = this.getPathFromUrl(url);

			if (url.indexOf("authToken") !== -1 && url.indexOf("locationId") !== -1) {
				return url;
			}

			return `${url}?authToken=${authToken}&locationId=${locationId}`;
		} catch (error) {
			console.log(error);
		}

		return url;
	},

	/**
	 * Stips the query params from the url
	 *
	 * @param {String} url
	 * @return {String}
	 */
	getPathFromUrl(url) {
		return url.split(/[?#]/)[0];
	},

	/**
	 * Check if the current browser is IE!
	 */
	isIE11() {
		return !!window.MSInputMethodContext && !!document.documentMode;
	},

	/**
	 * Build Image List from media_url field
	 *
	 * @param {String} mediaUrl
	 * @return {Array}
	 */
	buildImageList(mediaUrl) {
		let imageUrls = [];

		try {
			imageUrls = mediaUrl ? JSON.parse(mediaUrl) : [];
		} catch (err) {
			imageUrls = typeof mediaUrl === "string" ? [mediaUrl] : [];
		}

		return imageUrls;
	},

	/**
	 * Extracts all the media from a specific set of messages
	 *
	 * @param {Array} messages
	 * @return {Array}
	 */
	extractMedia(messages) {
		let mediaMessages = messages.filter(message => {
			return message.media.length > 0 || message.forward_media_object;
		});

		let media = [];
		for (let i = 0; i < mediaMessages.length; i++) {
			const message = mediaMessages[i];
			if (message.media) {
				media.push(...message.media);
			}
			if (message.forward_media_object) {
				media.push(message.forward_media_object);
			}
		}

		media = media.flatten();

		media = media.map(m => {
			if (m.type !== MEDIA_TYPES.image) {
				return null;
			}

			return {
				src: this.appendQueryAuthToken(m.download_url),
				file_name: m.file_name,
				height: m.height,
				width: m.width
			};
		});

		media = media.filter(item => {
			return item !== null;
		});

		return media;
	},

	/**
	 * Indicates whether the navbar is collapsed
	 *
	 * @return {Boolean}
	 */
	isNavigationCollapsed() {
		return navigationCollapsed;
	},

	/**
	 * Set whether or not the main navigation is collapsed
	 *
	 * @param {Boolean} value
	 */
	setNavigationCollapsed(value) {
		navigationCollapsed = value;
	},

	/**
	 * Native Number.toFixed(x) rounds up the number. This method will just strip off numbers
	 * @param {integer} num
	 * @param {integer} fixed
	 * @returns {string}
	 */
	toFixedWithoutRounding(num, fixed = 1) {
		var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
		return num.toString().match(re)[0];
	},

	/**
	 * Returns difference from input time
	 * @param {string} timestamp
	 * @returns {object} {days: 1, hours: 21, minutes: 45, seconds: 34}
	 */
	getTimeDifference(timestamp) {
		const diff = moment().diff(moment(timestamp));
		const diffDuration = moment.duration(diff);
		const days = parseInt(diffDuration.asDays(), 10);
		const hours = diffDuration.hours();
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds();
		return { days, hours, minutes, seconds };
	},
	/**
	 * Remove brackets, hyphens and spaces from a input number and check if it is valid
	 * @param {string} input
	 * @returns {boolean}
	 */
	isMobilePhoneValid(input) {
		const inputCopy = input.replace(/[- )(\+]/g, "");
		return isMobilePhone(inputCopy);
	},

	/**
	 * Test for an Emoji
	 *
	 * @param {String} string
	 * @return {Boolean}
	 */
	isSingleEmoji(emojiString) {
		let regex = RegExp(
			/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g
		);
		return regex.test(emojiString);
	},

	/**
	 * Indicates whether the string contains a handle
	 *
	 * @param {String} content
	 * @return {Boolean}
	 */
	isHandle(content) {
		return content.indexOf("@") !== -1;
	},

	/**
	 * Indicates whether the string contains an email address
	 * @param {String} content
	 * @return {Boolean}
	 */
	isEmail(content) {
		// eslint-disable-next-line
		let emailRegex = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
		return emailRegex.test(content);
	},

	/**
	 * Indicates whether the string contains a phone number
	 *
	 * @param {String} content
	 * @return {Boolean}
	 */
	isPhone(content) {
		let phoneRegex = new RegExp("/[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,7}/");
		return phoneRegex.test(content);
	},

	/**
	 * Detect Language Direction for a string
	 *
	 * @param {String} content
	 * @return {String}
	 */
	detectLanguageDirection(content) {
		let direction = "ltr";

		try {
			let language = franc.all(content, { only: ["eng", "arb"], minLength: 3 });
			if (LANGUAGE_DIRECTION[language[0][0]]) {
				direction = LANGUAGE_DIRECTION[language[0][0]];
			}
		} catch (error) {
			console.log(error);
		}

		return direction;
	},

	/**
	 * Retrieves a standard date object closest to the nearest 15 minute mark
	 *
	 * @return {Date}
	 */
	getNext15Minutes() {
		let next15Minutes = moment().add(15, "minutes");
		next15Minutes.minutes(Math.floor(next15Minutes.minutes() / 15) * 15);
		return next15Minutes.toDate();
	},

	/**
	 * Resolves the type of media based on the extension passed in (eg. png --> image, mp4 --> video, pdf --> file)
	 *
	 * @param {String} extension
	 * @returns {String} Media type
	 */
	resolveMediaType(extension) {
		if (this.isVideo(extension)) {
			return MEDIA_TYPES.video;
		} else if (this.isImage(extension)) {
			return MEDIA_TYPES.image;
		} else if (this.isAudio(extension)) {
			return MEDIA_TYPES.audio;
		}

		return MEDIA_TYPES.file;
	},

	/**
	 * Indicates the extension is for a video file
	 *
	 * @param {String} extension
	 */
	isVideo(extension) {
		return MEDIA_FILE_TYPES.video.indexOf(extension.trim().toLowerCase()) !== -1;
	},

	/**
	 * Indicates the extension is for a image file
	 *
	 * @param {String} extension
	 */
	isImage(extension) {
		return MEDIA_FILE_TYPES.image.indexOf(extension.trim().toLowerCase()) !== -1;
	},

	/**
	 * Indicates the extension is for a audio file
	 *
	 * @param {String} extension
	 */
	isAudio(extension) {
		return MEDIA_FILE_TYPES.audio.indexOf(extension.trim().toLowerCase()) !== -1;
	},

	/**
	 * Indicates whether the provided string has a possible youtube link in it
	 *
	 * @param {String} content
	 * @returns {Boolean}
	 */
	hasYoutubeLink(content) {
		return content.match(YOUTUBE_REGEX) !== null;
	},

	/**
	 * Checks if a url is a youtube link
	 *
	 * @param {String} youtubeUrl
	 * @returns {Boolean}
	 */
	isYoutubeLink(youtubeUrl) {
		var match = youtubeUrl.match(YOUTUBE_REGEX);
		return match && match[7].length === 11 ? true : false;
	},

	/**
	 * Extracts the youtube video ID of a youtube link
	 *
	 * @param {String} youtubeUrl
	 * @returns {String}
	 */
	getYoutubeId(youtubeUrl) {
		var match = youtubeUrl.match(YOUTUBE_REGEX);
		return match && match[7].length === 11 ? match[7] : "";
	},

	/**
	 * Get the start and end date given a word based date range (eg: "This Month")
	 * @param {String} range
	 */
	getDateRange(range = DATE_RANGES.thisMonth) {
		const dateRanges = { start: "", end: "" };

		if (range === DATE_RANGES.thisWeek) {
			// Get the date at start and end of the current week (Sunday to Saturday)
			dateRanges.start = moment()
				.startOf("week")
				.format(DATE_FORMAT);
			dateRanges.end = moment()
				.endOf("week")
				.format(DATE_FORMAT);
		} else if (range === DATE_RANGES.thisMonth) {
			// Get the date at start and end days of the current month
			dateRanges.start = moment()
				.startOf("month")
				.format(DATE_FORMAT);
			dateRanges.end = moment()
				.endOf("month")
				.format(DATE_FORMAT);
		} else if (range === DATE_RANGES.lastMonth) {
			// Get the date at start and end days of the previous month
			dateRanges.start = moment()
				.subtract(1, "month")
				.startOf("month")
				.format(DATE_FORMAT);

			dateRanges.end = moment()
				.subtract(1, "month")
				.endOf("month")
				.format(DATE_FORMAT);
		} else if (range === DATE_RANGES.allTime) {
			// Get the date range from 2017-01-01 to present
			dateRanges.start = moment("2017-01-01").format(DATE_FORMAT);
			dateRanges.end = moment().format(DATE_FORMAT);
		}

		return dateRanges;
	},

	/**
	 * Checks if a string contains only numbers
	 * @param {String} value
	 * @returns
	 */
	isStringNumeric(value) {
		try {
			if (!value || typeof value !== "string") {
				return false;
			}

			return value.replace(/"|'/g, "").match(/^[0-9]+$/g);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Converts "this text" -> "This Text"
	 *
	 * @param {String} str
	 * @returns {String}
	 */
	toTitleCase(str) {
		return str.replace(/\w\S*/g, function(txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	},

	/**
	 * Check if a string contact alphabet letters or spaces only
	 * @param {String} value
	 * @returns {Boolean}
	 */
	isStringLettersOnly(value) {
		let lettersOnlyRegex = /^[a-zA-Z\s]+$/gm;
		return lettersOnlyRegex.test(value);
	},

	/**
	 * Generate an array of random colors
	 * @param {Integer} length
	 * @returns {Array/String}
	 */
	generateColorsArray({ colors = [], length }) {
		for (let i = 0; i < length; i++) {
			colors.push(this.createRandomColor());
		}
		return colors;
	},

	/**
	 * Generate a random hex color code
	 * @returns {String} Hex color code
	 */
	createRandomColor() {
		var randomColor = "#000000".replace(/0/g, function() {
			return (~~(Math.random() * 16)).toString(16);
		});
		return randomColor;
	},

	/**
	 * Takes a React Input Reference, and triggers a HTML Form Invalid Message to appear.
	 *
	 * @param {Object} fieldReference
	 * @param {String} validityMessage
	 */
	validateField(fieldReference, validityMessage) {
		try {
			fieldReference.current.setCustomValidity(validityMessage);
			fieldReference.current.reportValidity();

			// This will clear the validity message so that it doesn't retrigger
			setTimeout(() => {
				fieldReference.current.setCustomValidity("");
			}, 1000);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Get the hour, minute and meridian given a 24 hour string
	 * @param {String} time A time value with the hour and minute ("02:30")
	 * @param {Boolean} convertTo12HourTime Whether to convert 24 hour time into 12 hour time
	 * @returns {Object} {hour, minute, meridian}
	 */
	getTimeData(time, convertTo12HourTime = true) {
		time = time.split(":");

		let timeHour = parseInt(time[0]);
		let timeMinute = parseInt(time[1]);
		let timeMeridian = null;

		if (convertTo12HourTime) {
			timeMeridian = MERIDIAN_TIMES_OPTIONS.am.value;

			// If the time is 12 or greater in 24 time, it is PM time
			if (timeHour >= 12) {
				timeMeridian = MERIDIAN_TIMES_OPTIONS.pm.value;
			}

			// In 12 hour time, 0 = 12 AM
			if (timeHour === 0) {
				timeHour = 12;
			}

			// Set the hour time properly
			if (timeHour > 12) {
				timeHour -= 12;
			}
		}

		return {
			hour: timeHour,
			minute: timeMinute,
			meridian: timeMeridian
		};
	},

	/**
	 * Get the relative date for chat cells, along with live times for dates under the hour
	 *
	 * @param {Date} date
	 * @return {String}
	 */
	getRelativeDate(date) {
		var now = moment(new Date());
		var chatDate = moment(date);

		if (now.diff(chatDate, "minutes") < 60) {
			return chatDate.fromNow();
		}

		return moment(date).calendar(null, {
			sameDay: "LT",
			nextDay: "[Tomorrow]",
			nextWeek: "dddd",
			lastDay: "[Yesterday] LT",
			lastWeek: "MMM D",
			sameElse: "MMM D"
		});
	},

	/**
	 * Check if a date is a birthday, the same month and date
	 *
	 * @param {Date} date
	 * @return {String}
	 */
	isBirthday(date) {
		if (!date) {
			return false;
		}

		var today = moment();
		date = moment(date);

		return today.date() === date.date() && today.month() === date.month();
	},

	/**
	 * Get the amount of time that has passed in buckets of time
	 *
	 * @param {Date} datePast
	 * @param {Date} dateFuture
	 * @return {String}
	 */
	getFormattedTimeElapsed(datePast, dateFuture) {
		let difference = moment.duration(moment(dateFuture).diff(moment(datePast)));

		let daysUsed = Math.floor(difference.asDays());
		let hoursUsed = Math.floor(difference.asHours());
		let minutesUsed = Math.floor(difference.asMinutes());
		let secondsUsed = Math.floor(difference.asSeconds());
		let millisecondsUsed = Math.floor(difference.milliseconds());

		if (daysUsed > 0) {
			return daysUsed === 1 ? daysUsed + " Day" : daysUsed + " Days";
		} else if (hoursUsed > 0) {
			return hoursUsed === 1 ? hoursUsed + " Hour" : hoursUsed + " Hours";
		} else if (minutesUsed > 0) {
			return minutesUsed === 1 ? minutesUsed + " Minute" : minutesUsed + " Minutes";
		} else if (secondsUsed > 0) {
			return secondsUsed === 1 ? secondsUsed + " Second" : secondsUsed + " Seconds";
		} else {
			return millisecondsUsed === 1 ? millisecondsUsed + " Millisecond" : millisecondsUsed + " Milliseconds";
		}
	},

	/**
	 * Check if arrays are "equal", ie the contents are the same. Order does matter
	 * @param {Array} a
	 * @param {Array} b
	 * @returns {Boolean}
	 */
	arraysEqual(a, b) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (var i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	},

	/**
	 * Guess the current timezone information.
	 * EX:
	 * localTimezone: America/Toronto
	 * localTimeZoneShortHand: -08:00
	 *
	 * @return {Object} {localTimeZone, localTimeZoneShortHand}
	 */
	getTimeZoneHelpers() {
		let localTimeZone = moment.tz.guess();
		let localTimeZoneShortHand = moment()
			.tz(moment.tz.guess())
			.format("z");
		let localTimeZoneString = `Timezone: ${localTimeZone} (${localTimeZoneShortHand})`;
		return { localTimeZone, localTimeZoneShortHand, localTimeZoneString };
	},

	/**
	 * Check if a password is valid
	 * @param {String} password
	 * @param {String} requirements Password requirements to check against
	 * @returns {Boolean}
	 */
	isValidPassword({ password, requirements = USER_PASSWORD_REQUIREMENTS }) {
		if (!requirements) {
			return true;
		}

		for (var requirement of Object.keys(requirements)) {
			let passwordRequirementsEntry = PASSWORD_REQUIREMENTS_GENERATOR[requirement];

			// Check how many of these characters are required
			let expected = requirements[requirement];

			// Test the password
			let passedTest = passwordRequirementsEntry.test(password, expected);

			if (!passedTest) {
				return false;
			}
		}

		return true;
	},

	/**
	 * Generated a string from a password requirement object
	 *
	 * @param {Object} requirementsObject ex: { characters: 8, uppercase: 1, lowercase: 1, number: 1, special: 1 }
	 * @return {String} ex: Password must have 8 characters, 1 lowercase, 1 uppercase, 1 number, 1 special.
	 */
	generateStringFromPasswordRequirements(requirementsObject = USER_PASSWORD_REQUIREMENTS) {
		let passwordText = "Password needs:";

		for (var key of Object.keys(requirementsObject)) {
			passwordText += ` ${requirementsObject[key]} ${key},`;
		}

		// Remove last comma
		passwordText = passwordText.slice(0, -1);
		passwordText += ".";

		return passwordText;
	},

	/**
	 * Check to see if we need to set a dark background and set it if needed
	 */
	async checkAndSetDarkBackground() {
		let isDarkModeTime = this.isDarkModeTime();

		if (isDarkModeTime) {
			this.setDarkBackground();
		}

		// TODO:  DH-3126
		// Set darkmode depending on local sunset time. This works, but was a bit too clunky for me IMO, since the coordinates take a split second to get
		// Also, instead of asking user for geolocation, we could always use some third party api to determine rough lat long coordinates based on ip address
		// let permissionResult = await navigator.permissions.query({
		// 	name: "geolocation"
		// });

		// if (!permissionResult || permissionResult.state === "denied") {
		// 	let isDarkModeTime = UtilityService.isDarkModeTime();
		// 	console.log(`1 isDarkModeTime: `, isDarkModeTime);
		// 	if (isDarkModeTime) {
		// 		this.setDarkBackground();
		// 	}
		// }

		// navigator.geolocation.getCurrentPosition(position => {
		// 	let isDarkModeTime = UtilityService.isDarkModeTime({ lat: position.coords.latitude, long: position.coords.longitude });
		// 	console.log(`2 isDarkModeTime: `, isDarkModeTime);

		// 	if (isDarkModeTime) {
		// 		this.setDarkBackground();
		// 	}
		// });
	},

	/**
	 * Set the dark background
	 */
	setDarkBackground() {
		document.body.classList.add("Common-bg--dark");
	},

	/**
	 * Determine if it is time for dark mode!
	 * @param {Number} lat
	 * @param {Number} long
	 * @returns {Boolean}
	 */
	isDarkModeTime(lat = null, long = null) {
		let isBeforeSunrise = this.isBeforeDefaultSunrise();
		let isAfterSunset = this.isAfterDefaultSunset();
		return isBeforeSunrise || isAfterSunset;
	},

	/**
	 * Determine if it's before sunrise based on lat/long or based on default sunrise times
	 * @param {Number} lat
	 * @param {Number} long
	 * @returns {Boolean}
	 */
	isBeforeSunrise({ lat, long }) {
		if (!lat || !long) {
			if (this.isBeforeDefaultSunrise()) {
				return true;
			}
		}

		// TODO: DH 3126
		// let times = sunCalc.getTimes(new Date(), lat, long);
		let times = null;

		if (!times || `${times.sunrise}` === "Invalid Date") {
			if (this.isBeforeDefaultSunrise()) {
				return true;
			}
		}

		let sunrise = moment(times.sunrise);
		let now = moment();

		if (now.isBefore(sunrise)) {
			return true;
		}

		return false;
	},

	/**
	 * Determine if it's before the default sunrise hour
	 * @returns {Boolean}
	 */
	isBeforeDefaultSunrise() {
		let now = moment();
		if (now.hour() < DEFAULT_SUNRISE_HOUR) {
			return true;
		}
	},

	/**
	 * Determine if it's after sunset based on lat/long or based on default sunset times
	 * @param {Number} lat
	 * @param {Number} long
	 * @returns {Boolean}
	 */
	isAfterSunset({ lat, long }) {
		if (!lat || !long) {
			if (this.isAfterDefaultSunset()) {
				return true;
			}
		}

		// TODO: DH 3126
		// let times = sunCalc.getTimes(new Date(), lat, long);
		let times = null;

		if (!times || `${times.sunset}` === "Invalid Date") {
			if (this.isAfterDefaultSunset()) {
				return true;
			}
		}

		let sunset = moment(times.sunset);
		let now = moment();

		if (now.isAfter(sunset)) {
			return true;
		}

		return false;
	},

	/**
	 * Determine if it's after the default sunset hour
	 * @returns {Boolean}
	 */
	isAfterDefaultSunset() {
		let now = moment();
		if (now.hour() > DEFAULT_SUNSET_HOUR) {
			return true;
		}
	},

	/**
	 * Check if a url is a valid https url
	 * @param {String} urlString
	 * @returns {Boolean}
	 */
	isValidHttpsUrl(urlString) {
		// Basic check
		let passedBasicCheck = false;

		try {
			let url = new URL(urlString);

			let urlPattern = new RegExp(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi);
			/**
			 * Regex explained
			 	/
				(http(s)?:\/\/.)?  // Optional scheme (http:// or https://)
				(www\.)?           // Optional www prefix
				[-a-zA-Z0-9@:%._\+~#=]{2,256}  // Domain name (may include special characters)
				\.[a-z]{2,6}       // Top-level domain (e.g., .com, .org)
				\b                 // Word boundary to ensure the match ends at a word boundary
				([-a-zA-Z0-9@:%_\+.~#?&//=]*)  // Optional path and query string
				/gi
			 */

			passedBasicCheck = urlPattern.test(urlString);
		} catch (_) {
			passedBasicCheck = false;
		}

		if (passedBasicCheck) {
			return true;
		}

		// Advance check
		var urlPattern = new RegExp(
			"^(https?:\\/\\/)?" + // validate protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		); // validate fragment locator

		return !!urlPattern.test(urlString);
	},

	/**
	 * Converts a string into a hex colour
	 *
	 * @param {string} str
	 * @returns
	 */
	stringToColour(str) {
		var hash = 0;
		for (var i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		var colour = "#";
		for (var i = 0; i < 3; i++) {
			var value = (hash >> (i * 8)) & 0xff;
			colour += ("00" + value.toString(16)).substr(-2);
		}
		return colour;
	},

	/**
	 * Get's the frontend IP address
	 *
	 * @param {String} type
	 * @returns String
	 */
	async getIP(type = "v4") {
		let url = type === "v4" ? "https://api.ipify.org/" : "https://api6.ipify.org/";

		try {
			const response = await fetch(url);
			const ip = response.text();
			return ip;
		} catch (e) {
			return null;
		}
	},

	/**
	 * Turn a formatted phone number into just numbers
	 * @param {String} phone
	 * @param {String} region
	 * @returns {String}
	 */
	deFormatPhoneNumber(phone, region = "US") {
		try {
			if (!phone) {
				return phone;
			}

			let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
			phone = phoneUtil.parse(phone, region);
			phone = phone.values_[2];

			return phone.toString();
		} catch (error) {
			console.log(error);
			return phone;
		}
	},

	/**
	 * Prefix the area code to a phone number if needed
	 * @param {String} phone A phone number
	 * @param {String} prefix Area code
	 * @returns {String}
	 */
	prefixAreaCode(phone, prefix) {
		try {
			if (!phone) {
				return phone;
			}

			phone = this.deFormatPhoneNumber(phone);

			// If the phone number is 7 digits and we have a valid prefix, add the prefix to the number
			if (prefix && prefix.length > 0 && phone.length === 7) {
				phone = `${prefix}${phone}`;
			}

			return phone;
		} catch (error) {
			return phone;
		}
	},

	/**
	 * Formats a phone number into E164 format
	 * @param {String} phone - A phone number
	 * @param {String} region - A string representing a region (defaults to "US")
	 * @param {String} areaCode - A default area code if the phone number is 7 digits
	 * @returns {String} A string containing the E164 formatted number, or null on error
	 */
	formatPhoneNumber(phone, region = "US", areaCode = null) {
		let PNF = libphonenumber.PhoneNumberFormat;
		let phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
		let output = phone;

		try {
			if (areaCode) {
				output = this.prefixAreaCode(output, areaCode);
			}
			output = phoneUtil.parse(output, region);
			output = phoneUtil.format(output, PNF.E164);
		} catch (error) {
			return null;
		}

		return output;
	},

	/**
	 * Generates a unique string identifier
	 *
	 * @param {Number} version we should use 4 by default
	 * @return {String}
	 */
	uuid(version = 4) {
		if (version === 1) {
			return uuidv1();
		}

		return uuidv4();
	},

	/**
	 * Condences a URL
	 * Examples:
	 *
	 * Simple:
	 * Input: https://try.demandhub.co/a/b/c/get-free-demo/
	 * Output:https://try.demandhub.co/.../c/get-free-demo
	 *
	 *
	 * Advance:
	 * Input: https://www.demandhub.co/articles/google-review-not-showing-up/#:~:text=Typically%2C%20Google%20reviews%20will%20show,for%20a%20review%20to%20appear
	 * Output: https://www.demandhub.co/.../articles/google-review-not-showing-up
	 *
	 * @param {String} url
	 * @returns {String}
	 */
	condenseURL({ url }) {
		let formattedURL = url.trim();

		// Check what we have to begin with at the front of the string
		const hasHTTP = formattedURL.startsWith("http://");
		const hasHTTPS = formattedURL.startsWith("https://");
		const hasWWW = formattedURL.startsWith("www.");

		// Setup what we need to pass into URL()
		if (!hasHTTP && !hasHTTPS) {
			if (!hasWWW) {
				formattedURL = "www." + formattedURL;
			}
			formattedURL = "https://" + formattedURL;
		}

		const urlObject = new URL(formattedURL);
		const { protocol, hostname, pathname } = urlObject;

		// Split the path to be ww.goog.ca/.../end
		const pathParts = pathname.split("/").filter(part => part !== "");
		const condensedPath = pathParts.length > 2 ? ["...", ...pathParts.slice(-2)] : pathParts;

		// Add the http/https if it was in the original
		let condensedURL = "";
		if (hasHTTP) {
			condensedURL += "http://";
		} else if (hasHTTPS) {
			condensedURL += "https://";
		}

		// Add WWW if it was in the original
		if (hasWWW) {
			condensedURL += "www.";

			// If hostname have us a result with wwww, skip over it
			if (hostname.startsWith("www.")) {
				// Skip "www."
				condensedURL += hostname.substring(4, hostname.length);
			} else {
				condensedURL += hostname;
			}
		} else {
			condensedURL += hostname;
		}

		// Join everything together
		if (condensedPath.length > 0) {
			condensedURL += "/" + condensedPath.join("/");
		}

		return condensedURL;
	},

	/**
	 * To see if text should be black or white against a background colour
	 *
	 * @param {String} backgroundColor in hex
	 * @returns {Bool}
	 */
	shouldTextBeWhite(backgroundColor) {
		// Convert hex to RGB
		const r = parseInt(backgroundColor.substring(1, 3), 16);
		const g = parseInt(backgroundColor.substring(3, 5), 16);
		const b = parseInt(backgroundColor.substring(5, 7), 16);

		// Calculate luminance
		const luminance = 0.2126 * (r / 255) + 0.7152 * (g / 255) + 0.0722 * (b / 255);

		return luminance <= 0.5;
	},

	/**
	 * Download Qr Code
	 *
	 * @param {Number} id
	 * @param {String} fileName
	 */
	downloadQrCode({ id, fileName = "qrcode.png" }) {
		const canvas = document.getElementById(id);

		const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");

		let a = document.createElement("a");
		a.href = pngUrl;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		a.remove();
	},

	/**
	 * Check if a variable is an int
	 * @param {Integer} value
	 * @returns {Boolean}
	 */
	isInt(value) {
		return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
	},

	/**
	 * Get cached data, if data is stale method will return null
	 * @param {String} key
	 * @param {Integer} timeout milliseconds
	 * @param {Integer} maxRandomTimeout
	 * @returns {Object/null} Returns cached data or, if none is found, returns null if no cached data is found
	 */
	async getCachedData({ key, timeout = 500, maxRandomTimeout = 500 }) {
		// A simple way to avoid race conditions
		await this.timeout(this.getRandomBetween(0, maxRandomTimeout));

		// If the cached data is "stale", delete if from the cache
		if (cachedData[key] && moment(cachedData[key].timestamp).isBefore(moment(), "seconds")) {
			delete cachedData[key];
		}

		// If we have data = null, and the timestamp is in the future, we have made a query for this key
		if (cachedData[key] && moment(cachedData[key].timestamp).isAfter(moment(), "seconds") && cachedData[key].data === null) {
			// If we are here, that means the https request already has been made, we will wait here to check if the fetch has complete
			await this.checkCachedData({ key, timeout, retries: 8 });
		}

		// If the data is not cached yet, return null and pre-cache data
		if (!cachedData[key]) {
			this.cacheData({ key, data: null });
			return null;
		}

		// If the cached data exists, return the data
		return cachedData[key].data;
	},

	/**
	 * Poll to see if we have received cached data
	 *
	 * @param {String} key
	 * @param {Integer} timeout milliseconds
	 * @param {Integer} retries
	 * @returns
	 */
	async checkCachedData({ key, timeout, retries }) {
		try {
			if (retries <= 0) {
				return;
			}

			await this.timeout(timeout);

			// If the data is in the cache, we can stop polling
			if (cachedData[key] && cachedData[key].data) {
				return;
			}
			retries--;

			await this.checkCachedData({ key, timeout, retries });
		} catch (error) {
			console.log(error);
			return;
		}
	},

	/**
	 * Cache data
	 * @param {String} key
	 * @param {Object} data
	 * @param {Integer} timeout
	 */
	cacheData({ key, data, timestamp }) {
		if (!timestamp) {
			// Cache data for 10 minutes
			timestamp = moment().add(600, "seconds");
		}

		cachedData[key] = {
			data,
			timestamp
		};
	},

	/**
	 * Function that generates a random number between low and high.
	 *
	 * @param {Number} low low bound on the random number
	 * @param {Number} high high bound on the random number
	 *
	 * @returns {Number} number between the low and high range
	 */
	getRandomBetween: function(low, high) {
		return Math.random() * (high - low) + low;
	}
};

export default UtilityService;
