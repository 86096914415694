export const SIGN_UP_COLUMNS = {
	legal_business_name: {
		id: "legal_business_name",
		value: "Business Name",
		sortable: true,
		sortField: "legal_business_name",
		width: 2
	},
	state: {
		id: "state",
		value: "State",
		sortable: true,
		sortField: "state",
		width: 1
	},
	contact_name: {
		id: "contact_name",
		value: "Contact Name",
		sortable: true,
		sortField: "contact_name",
		width: 1
	},
	contact_phone: {
		id: "contact_phone",
		value: "Contact Phone",
		sortable: true,
		sortField: "contact_phone",
		width: 1
	},
	contact_email: {
		id: "contact_email",
		value: "Contact Email",
		sortable: true,
		sortField: "contact_email",
		width: 2
	},
	state_prov: {
		id: "state_prov",
		value: "State Or Province",
		sortable: false,
		sortField: "state_prov",
		width: 1
	},
	country: {
		id: "country",
		value: "Country",
		sortable: false,
		sortField: "country",
		width: 1
	},
	is_free_trial: {
		id: "is_free_trial",
		value: "Is Free Trial",
		sortable: true,
		sortField: "is_free_trial",
		width: 1
	},
	start_date: {
		id: "start_date",
		value: "Start Date",
		sortable: true,
		sortField: "start_date",
		width: 1
	},
	actions: {
		id: "actions",
		value: "Actions",
		sortable: false,
		sortField: "actions",
		width: 2,
		notClickable: true
	}
};

export const SIGN_UP_STATE_FILTERS = {
	all: {
		id: "all",
		display: "All",
		order: 1
	},
	draft: {
		id: "draft",
		display: "Draft",
		order: 2
	},
	sent: {
		id: "sent",
		display: "Sent",
		order: 3
	},
	signed: {
		id: "signed",
		display: "Signed",
		order: 4
	},
	submitted: {
		id: "submitted",
		display: "Submitted",
		order: 5
	}
};

export const CURRENCY_OPTIONS = [
	{ value: "CAD", label: "CAD" },
	{ value: "USD", label: "USD" }
];

export const FREQUENCY_OPTIONS = [
	{ value: "monthly", label: "Monthly" },
	{ value: "quarterly", label: "Quarterly" },
	{ value: "biannually", label: "Biannually" },
	{ value: "annually", label: "Annually" }
];

export const CONTRACT_TYPE_OPTIONS = [
	{ value: "standard", label: "Standard (Annual)" },
	{ value: "annual_month_to_month", label: "Annual Month To Month" },
	{ value: "annual_3_month_opt_out", label: "Annual 3 Month Opt Out" },
	{ value: "month_to_month", label: "Month To Month" }
];

export const SIGN_UP_STATES = {
	draft: "draft",
	sent: "sent",
	signed: "signed",
	submitted: "submitted"
};

export const POORLY_MANAGED_COUPONS = [
	"$30 off CAD",
	"$50 off CAD",
	"$30 off USD",
	"$50 off USD",
	"One Time Setup Discount CAD",
	"One Time Setup Discount USD"
];

export const FEATURE_LIST = [
	{ value: "reviews", label: "Reviews" },
	{ value: "webchat", label: "Web Chat" },
	{ value: "messenger", label: "Messenger Platform" },
	{ value: "reviewsWidget", label: "Reviews Widget" },
	{ value: "mobile", label: "Mobile Application" },
	{ value: "reengagements", label: "Patient Reengagements" },
	{ value: "leadManager", label: "Lead Manager" },
	{ value: "aiReceptionist", label: "AI Receptionist" }
];
