import _ from "underscore";
import UserService from "./UserService";
import CompanyService from "./CompanyService";
import Kichiri from "./KichiriService";
import UtilityService from "./UtilityService";

import { CONVERSATION } from "../constants/Messenger";

let conversations = {};
let cachedCurrentConversation = null;

const TeamChatService = {
	/**
	 * Fetches all the team conversations for a specific user
	 *
	 * @param {Number} userId
	 * @param {Number} companyId
	 * @param {String} [type="dm"]
	 * @param {String} searchTerm
	 * @return {Promise}
	 */
	async fetchConversations({ userId, companyId, type = CONVERSATION.dm, searchTerm = "" }) {
		let user = UserService.get();

		let conversations = [];

		try {
			let response = await Kichiri.message.fetchInternalConversations(
				{},
				{
					userId,
					companyId,
					type,
					searchTerm
				},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return conversations;
	},

	/**
	 * Fetches a team chat conversation and the contextual data for it as well
	 *
	 * @return {Promise}
	 */
	async fetchConversationDetails(conversationId) {
		try {
			let response = await Kichiri.message.fetchInternalConversation({ conversationId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update the name of a conversation
	 *
	 * @param {Number} conversationId
	 * @param {String} name
	 * @param {String} channelType
	 * @return {Promise}
	 */
	async updateInternalConversation({ conversationId, name, channelType }) {
		try {
			let response = await Kichiri.message.updateInternalConversation({ conversationId, name, channelType }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update all of the user preferences for a specific conversation
	 *
	 * @param {Number} conversationId
	 * @param {Number} userId
	 * @param {Boolean} isMentionsOnly
	 * @param {Boolean} isAdmin
	 * @return {Promise}
	 */
	async updateUserPreferences(conversationId, userId, isMentionsOnly, isAdmin) {
		try {
			await Kichiri.message.updateInternalConversationUserPreferences({ conversationId, userId, isMentionsOnly, isAdmin }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Remove members from a team chat conversation
	 *
	 * @param {Number} conversationId
	 * @param {Array} userIds
	 * @return {Promise}
	 */
	async removeMembers(conversationId, userIds) {
		try {
			await Kichiri.message.removeInternalConversationMember({ conversationId, userIds }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches all the users for that company
	 *
	 * @param {Number} companyId
	 * @param {Boolean} enableTeamChat
	 * @return {Promise}
	 */
	async fetchUsers({ companyId, enableTeamChat }) {
		try {
			let response = await Kichiri.user.fetchAssignable({}, { companyId, enableTeamChat }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(`An error occured trying to fetch assignable users ${error}`);
		}

		return [];
	},

	/**
	 * Add members to a team chat conversation
	 *
	 * @param {Number} conversationId
	 * @param {Array} userIds
	 * @return {Promise}
	 */
	async addMembers(conversationId, userIds) {
		try {
			await Kichiri.message.addInternalConversationMember({ conversationId, userIds }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a team message with new content
	 *
	 * @param {Number} messageId
	 * @param {String} content
	 * @return {Promise}
	 */
	async updateMessage(messageId, content) {
		try {
			let response = await Kichiri.message.updateMessage({ messageId, content }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Indicates whether TeamChat is enabled for this user and company
	 *
	 * @return {Boolean}
	 */
	isEnabled() {
		let user = UserService.get();
		let isEnabled = localStorage.getItem(`dh_teamchat_enabled_user_${user.id}`);

		if (JSON.parse(isEnabled)) {
			return true;
		}

		return false;
	},

	/**
	 * Determine from the backend if Team Chat is enabled for this user and company
	 *
	 * @return {Boolean}
	 */
	async checkEnabled() {
		let user = UserService.get();
		try {
			let latestUser = await UserService.fetchUser(user.id);

			if (!latestUser) {
				return false;
			}

			let company = await CompanyService.fetchCompany(latestUser.company_id);
			let isEnabled = user.enable_teamchat && company.enable_teamchat;
			localStorage.setItem(`dh_teamchat_enabled_user_${user.id}`, isEnabled);

			return isEnabled;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Cache the notification preference for a specific conversation and user
	 *
	 * @param {Number} conversationId
	 * @param {Number} userId
	 * @param {Boolean} isMentionsOnly
	 */
	cacheNotificationPreference(conversationId, userId, isMentionsOnly) {
		let key = "dh_teamchat_notification_preferences_user_id_" + userId + "_conversation_id_" + conversationId;
		localStorage.setItem(key, JSON.stringify(isMentionsOnly));
	},

	/**
	 * Go through all of the conversations for a user and cache the notification preferences
	 *
	 * @param {Array} conversations
	 */
	storeNotificationPreferences(conversations) {
		let user = UserService.get();

		try {
			for (let c of conversations) {
				let isMentionsOnly = c["Users.ConversationUser.is_mentions_only"] === 1;
				this.cacheNotificationPreference(c.id, user.id, isMentionsOnly);
			}
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Determines if a user has isMentionsOnly set for a specific conversation
	 *
	 * @param {Number} conversationId
	 * @param {Number} userId
	 * @return {Boolean}
	 */
	isMentionsOnly(conversationId, userId) {
		try {
			let key = "dh_teamchat_notification_preferences_user_id_" + userId + "_conversation_id_" + conversationId;
			let isMentionsOnly = JSON.parse(localStorage.getItem(key));
			return isMentionsOnly;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Indicates if a specific message's content should notify the user
	 *
	 * @param {Number} conversationId
	 * @param {String} content
	 * @return {Boolean}
	 */
	hasNotificationPreference(conversationId, content) {
		let user = UserService.get();

		if (this.isMentionsOnly(conversationId, user.id)) {
			return content.indexOf("@" + user.handle) !== -1 || content.indexOf("@all") !== -1;
		}

		return true;
	},

	/**
	 * Helper function to hide a team chat
	 *
	 * @param {Number} conversationId
	 * @return {Object}
	 */
	async hideConversation(conversationId) {
		return await this.toggleHideConversation(conversationId, true);
	},

	/**
	 * Helper function to unhide a team chat
	 *
	 * @param {Number} conversationId
	 * @return {Object}
	 */
	async unhideConversation(conversationId) {
		return await this.toggleHideConversation(conversationId, false);
	},

	/**
	 * Allows the ability to hide or unhide a conversation
	 *
	 * @param {Number} conversationId
	 * @param {Boolean} hide
	 * @return {Object}
	 */
	async toggleHideConversation(conversationId, hide) {
		let userToken = UserService.getAuthToken();

		try {
			let response = await Kichiri.message.toggleHide({ conversationId, hide }, {}, userToken);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch the internal conversation unread count
	 *
	 * @param {Promise}
	 */
	async fetchInternalUnreadCount() {
		let user = UserService.get();
		let location = UserService.getActiveLocation();

		try {
			let response = await Kichiri.message.fetchInternalUnreadCount(
				{},
				{
					userId: user.id,
					locationId: location.id
				},
				user.auth_token
			);

			conversations = response.data;

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return {};
	},

	/**
	 * Retrieves the unread count for a specific conversation
	 *
	 * @param  {Number} conversationId
	 * @return {Number}
	 */
	getUnreadCount(conversationId) {
		return conversations[conversationId] || 0;
	},

	/**
	 * Retrieves a total unread count for all the unread messages
	 *
	 * @return {Number}
	 */
	async getTotalUnreadCount() {
		let convos = await this.fetchInternalUnreadCount();

		let total = 0;
		for (let key in convos) {
			if (key === "mentions") {
				continue;
			}
			total += convos[key] || 0;
		}

		return total;
	},

	/**
	 * Indicates whether the current user has been mentioned in any chats
	 *
	 * @return {Boolean}
	 */
	hasMentions() {
		return conversations.mentions > 0;
	},

	/**
	 * Mark a team chat message as unread
	 *
	 * @param {Number} messageId
	 * @param {Number} conversationId
	 * @param {Number} userId
	 */
	async markAsUnread(messageId, conversationId, userId) {
		try {
			let response = await Kichiri.message.markAsUnread({ messageId, conversationId, userId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Caches the unread message for the current team chat conversation in the thread
	 *
	 * @param {Number} unreadMessageId
	 * @returns
	 */
	setUnreadMessage(unreadMessageId) {
		if (!cachedCurrentConversation) {
			return;
		}

		cachedCurrentConversation.unread_message_id = unreadMessageId;
	},

	/**
	 * Indicates whether or not a message is the first new message in the thread
	 *
	 * @param {Object} message
	 * @param {Object} previousMessage
	 */
	shouldAppendUnreadIndicator(message) {
		if (!cachedCurrentConversation) {
			return false;
		}

		let currentMessageId = message.id;
		let unreadMessageId = cachedCurrentConversation.unread_message_id;

		if (currentMessageId === unreadMessageId) {
			return true;
		}

		return false;
	},

	/**
	 * Fetch all the internal messages of a team conversation
	 *
	 * @param {Number} conversationId
	 * @param {Number} userId
	 * @param {Number} messageId
	 * @param {Boolean} fetchTop
	 * @param {Boolean} fetchBottom
	 * @return {Promise}
	 */
	async fetchMessages({ conversationId, userId, messageId, fetchTop, fetchBottom }) {
		try {
			let { data } = await Kichiri.message.fetchInternalMessages(
				{},
				{
					conversationId,
					userId,
					messageId,
					windowMode: true,
					fetchTop,
					fetchBottom
				},
				UserService.getAuthToken()
			);

			cachedCurrentConversation = data.conversationUser;

			return data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetches all the media messages for the specified teamchat conversation
	 *
	 * @param {Number} conversationId
	 * @param {Number} userId
	 * @param {Number} limit
	 * @param {Number} offset
	 * @returns {Array}
	 */
	async fetchMediaForConversation({ conversationId, userId, limit = 50, offset = 0 }) {
		let user = UserService.get();
		let locationId = UserService.getActiveLocation().id;

		try {
			let queryParams = {
				limit,
				userId,
				offset
			};

			// Fetch the media messages within the limit and offset
			let { data: media } = await Kichiri.message.fetchTeamChatMedia({ conversationId }, queryParams, user.auth_token);

			// Add authentication to the media urls
			for (let i = 0; i < media.length; i++) {
				let mediaItem = media[i];
				mediaItem.download_url = UtilityService.appendQueryAuthToken(mediaItem.download_url, locationId, user.auth_token);
			}

			return media;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Searches a team chat conversation foπr messages that match the provided search term
	 *
	 * @param {Number} conversationId
	 * @param {String} searchTerm
	 */
	async searchConversation(conversationId, searchTerm) {
		let user = UserService.get();

		try {
			let response = await Kichiri.message.searchInternalConversation(
				{
					conversationId
				},
				{
					searchTerm
				},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Check if the user is able to forward customer messages
	 * @returns {Boolean}
	 */
	isForwardMessageEligible() {
		try {
			let user = UserService.get();
			return user.enable_teamchat && user.GroupPermission.forward_customer_messages && this.isEnabled();
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Purge the teamchat messages within a given range
	 *
	 * @param {Number} conversationId
	 * @param {String} purgeStartDate in YYYY-MM-DD
	 * @param {String} purgeEndDate in YYYY-MM-DD
	 */
	async purgeTeamchatMessages({ conversationId, purgeStartDate, purgeEndDate }) {
		let currentUserLocationId = UserService.getActiveLocation().id;

		try {
			let response = await Kichiri.message.purgeTeamchatMessages(
				{ currentUserLocationId, conversationId, purgeStartDate, purgeEndDate },
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Archive a teamchat conversation
	 *
	 * @param {Number} conversationId
	 */
	async archiveConversation({ conversationId }) {
		try {
			let response = await Kichiri.message.archiveTeamchatConversation({ conversationId }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Mark a team chat message as unread
	 *
	 * @param {Number} messageId
	 * @param {Number} conversationId
	 * @param {Number} userId
	 */
	async markAsUnread(messageId, conversationId, userId) {
		try {
			let response = await Kichiri.message.markAsUnread({ messageId, conversationId, userId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch TeamChat Scheduled Messages By Conversation
	 * @param {Integer} conversationId
	 * @returns {Array}
	 */
	async fetchScheduledMessages(conversationId) {
		try {
			let { data } = await Kichiri.message.fetchTeamChatScheduledMessages(
				{},
				{
					conversationId
				},
				UserService.getAuthToken()
			);

			return data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch Conversations that have TeamChat Scheduled Messages
	 * @returns {Array}
	 */
	async fetchConversationsWithQueuedMessages() {
		try {
			let { data } = await Kichiri.message.fetchConversationsWithQueuedMessages({}, {}, UserService.getAuthToken());

			return data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Count the number of TeamChat Scheduled Messages By Conversation
	 * @param {Integer} conversationId
	 * @returns {Integer}
	 */
	async countScheduledMessages(conversationId) {
		try {
			let { data } = await Kichiri.message.countTeamChatScheduledMessages(
				{},
				{
					conversationId
				},
				UserService.getAuthToken()
			);

			return data.count;
		} catch (error) {
			console.log(error);
		}

		return 0;
	},

	/**
	 * Update a TeamChat Scheduled Message
	 * @param {Integer} companyId
	 * @param {Integer} locationId
	 * @param {Integer} conversationId
	 * @param {String} content
	 * @param {Array} mediaIds
	 * @param {Date} sendAfter
	 *
	 * @returns {Object}
	 */
	async createScheduledMessage({ companyId, locationId, conversationId, content, mediaIds, sendAfter }) {
		try {
			let response = await Kichiri.message.createTeamChatScheduledMessage(
				{ companyId, locationId, conversationId, content, mediaIds, sendAfter },
				{},
				UserService.getAuthToken()
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a TeamChat Scheduled Message
	 * @param {Integer} id
	 * @param {String} content
	 * @param {Date} sendAfter
	 * @param {String} status
	 *
	 * @returns {Object}
	 */
	async updateScheduledMessage({ id, content, sendAfter, status }) {
		try {
			let response = await Kichiri.message.updateTeamChatScheduledMessage({ id, content, sendAfter, status }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch TeamChat
	 *
	 * @param {Integer} groupId
	 * @param {String} conversationType
	 *
	 * @returns {Array}
	 */
	async fetchTeamChatConversationGroups({ groupId, conversationType }) {
		try {
			let response = await Kichiri.message.fetchTeamChatConversationGroups({}, { groupId, conversationType }, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Fetch conversation groups
	 * @param {Integer} id
	 * @returns {Object}
	 */
	async fetchConversationGroup({ id }) {
		try {
			let response = await Kichiri.message.fetchTeamChatConversationGroups({ id }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a TeamChat Conversation Group
	 * @param {Integer} name
	 * @param {String} conversationType
	 *
	 * @returns {Object}
	 */
	async createConversationGroup({ name, conversationType }) {
		try {
			let response = await Kichiri.message.createTeamChatConversationGroup({ name, conversationType }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a TeamChat Conversation Group
	 * @param {Integer} id
	 * @param {String} name
	 * @param {Array/Integer} conversationIds
	 * @param {Boolean} status
	 *
	 * @returns {Object}
	 */
	async updateConversationGroup({ id, name, conversationIds, status }) {
		try {
			let response = await Kichiri.message.updateTeamChatConversationGroup({ id, name, conversationIds, status }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Sort teamchat conversations
	 * @param {Array/Object} conversations Teamchat conversations
	 */
	sortConversations(conversations) {
		conversations = conversations
			.sort((a, b) => b.name.toLowerCase() - a.name.toLowerCase())
			.sort((a, b) => {
				if (a.type === CONVERSATION.channel && b.type === CONVERSATION.dm) {
					return -1;
				}
				if (a.type === CONVERSATION.dm && b.type === CONVERSATION.channel) {
					return 1;
				}
				return 0;
			});

		return conversations;
	}
};

export default TeamChatService;
