import moment from "moment";

import Kichiri from "./KichiriService";
import LocationService from "./LocationService";
import ReengagementService from "./ReengagementService";
import UserService from "./UserService";

import { CRM_INTEGRATIONS } from "../constants/CRMIntegration";
import UtilityService from "./UtilityService";

const CrmIntegrationService = {
	/**
	 * Fetch Crm Integrations
	 * @param {Object} params Query params
	 * @param {Boolean} parseMetaData If we want the meta datas of each integration parsed
	 * @returns {Promise} A list of crm integrations
	 */
	async fetch(locationId, params, parseMetaData = false) {
		let query = {
			searchTerm: null,
			sortField: null,
			sortOrder: null,
			limit: 30,
			offset: null
		};

		query = Object.assign(query, params);

		let key = `CrmIntegrationService:fetch:${JSON.stringify(query)}`;

		let cachedData = await UtilityService.getCachedData({
			key
		});

		// If the cached data was not found
		if (cachedData) {
			return cachedData;
		}

		try {
			const response = await Kichiri.crm_integrations.fetch({ locationId }, query, UserService.getAuthToken());

			let integrations = response.data;
			if (parseMetaData) {
				for (let i = 0; i < integrations.length; i++) {
					const integration = integrations[i];

					try {
						integration.meta_data = JSON.parse(integration.meta_data);
					} catch (error) {
						console.log(`parse error - `, error);
						integration.meta_data = null;
					}
				}
			}

			UtilityService.cacheData({ key, data: integrations });

			return integrations;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Fetch CRM integration details for a rest integration (such as Janeapp)
	 * @param {Integer} integrationId The id of the CRM integration that is a rest api (such as Janeapp)
	 * @returns {Promise} The CRM integration details with decoded metadata, or false on error
	 */
	async fetchRestIntegration(integrationId) {
		try {
			const response = await Kichiri.crm_integrations.fetchRestIntegration({ integrationId }, {}, UserService.getAuthToken());

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Test the credentials/connection for a CRM integration (such as Janeapp))
	 * @param {Integer} integrationId The id of the CRM integration that is a rest api
	 * @param {String} tenantId The tenant id
	 * @param {String} login The login/username
	 * @param {String} password The password
	 * @param {String} clientSecret The client secret like api key if password already exists
	 * @param {String} practiceName The name of the practice if required by the intergration
	 * @param {String} code An optional code
	 * @param {String} accessToken An optional access token
	 * @returns {Promise} The status of the test { status, message }
	 */
	async testRestIntegration({ integrationId, tenantId, login, password, clientSecret, code, practiceName, accessToken }) {
		try {
			const response = await Kichiri.crm_integrations.testRestIntegration(
				{ integrationId, tenantId, login, password, clientSecret, code, practiceName, accessToken },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Fetch the locations from a rest-based CRM integration (such as Janeapp)
	 * @param {Integer} integrationId The id of the CRM integration that is a rest api (such as Janeapp)
	 * @returns {Array} An array of locations, or empty array on error
	 */
	async fetchRestLocations(integrationId) {
		try {
			const response = await Kichiri.crm_integrations.fetchRestLocations({ integrationId }, {}, UserService.getAuthToken());

			if (response.data && response.data.locations) {
				return response.data.locations;
			}
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	/**
	 * Update the CRM location id for a rest-based CRM integration (such as Janeapp)
	 * @param {Integer} integrationId The id of the CRM integration that is a rest api (such as Janeapp)
	 * @param {String} locationId the key name for the crmLocationId value
	 * @param {String} crmLocationId The location id within the particular CRM (such as Janeapp's location id, not ours)
	 * @param {String} locationName the key name for the crmLocationName value
	 * @param {String} crmLocationName
	 * @returns {Boolean} True on success, false otherwise
	 */
	async updateRestLocation({ integrationId, locationId, crmLocationId, locationName, crmLocationName }) {
		try {
			const response = await Kichiri.crm_integrations.updateRestLocation(
				{ integrationId, locationId, crmLocationId, locationName, crmLocationName },
				{},
				UserService.getAuthToken()
			);

			if (response.data && response.data.status) {
				return true;
			}
		} catch (error) {
			console.log(error);
		}

		return false;
	},

	/**
	 * Create a crm integration
	 * @param {Number} locationId
	 * @param {String} name
	 * @param {String} crmType
	 * @param {String} crmVersion
	 * @param {String} integrationType
	 * @param {String} direction
	 * @param {String} syncType
	 * @param {String} metadata
	 * @param {String} runType
	 * @param {Number} inboxId
	 * @param {Array} schedules
	 */
	async create({ locationId, name, crmType, crmVersion, integrationType, direction, syncType, metadata, runType, inboxId, schedules }) {
		try {
			await Kichiri.crm_integrations.createCrmIntegration(
				{ locationId: locationId, name, crmType, crmVersion, integrationType, direction, syncType, metadata, runType, inboxId, schedules },
				{},
				UserService.getAuthToken()
			);
			return true;
		} catch (error) {
			console.log(error);
		}
		return false;
	},

	/**
	 * Update a crm integration
	 * @param {Number} id
	 * @param {String} name
	 * @param {String} crmType
	 * @param {String} crmVersion
	 * @param {String} integrationType
	 * @param {String} direction
	 * @param {String} syncType
	 * @param {String} metadata
	 * @param {String} runType
	 * @param {String} status
	 * @param {Number} inboxId
	 * @param {Array} schedules
	 */
	async update({ id, name, crmType, crmVersion, integrationType, direction, syncType, metadata, runType, status, inboxId, schedules }) {
		try {
			let response = await Kichiri.crm_integrations.updateCrmIntegration(
				{ crmId: id, name, crmType, crmVersion, integrationType, direction, syncType, metadata, runType, status, inboxId, schedules },
				{},
				UserService.getAuthToken()
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Create a referral
	 * @param {String} data
	 */
	async createReferral(data) {
		try {
			await Kichiri.crm.createReferral({ data }, {}, UserService.getAuthToken());
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Fetch the enums related to crm integrations (crm types, integration types, sync types, run types, etc...)
	 * @returns {Object}
	 */
	async fetchEnum() {
		try {
			const response = await Kichiri.crm_integrations.fetchEnum({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Trigger a crm integration
	 * @param {Integer} locationId
	 * @param {String} crmType
	 * @param {String} metadata
	 * @returns
	 */
	async integrationTrigger({ locationId, crmType, metadata }) {
		try {
			await Kichiri.crm_integrations.integrationTrigger({ locationId, crmType, metadata }, {}, UserService.getAuthToken());

			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Check if the crms have been setup
	 * @returns {Object}
	 */
	async crmIntegrationsSetupStatus() {
		let integrationsExpected = false;
		let integrationsActive = true;

		try {
			let isReengagementsEnabled = ReengagementService.isReengagementEnabled();
			let isAutoReviewRequestEnabled = LocationService.isAutoReviewRequestsEnabled();
			let location = UserService.getActiveLocation();
			let locationId = location.id;
			let isAppointmentReminders = location.LocationFeature.appointment_reminders;

			integrationsExpected = isAutoReviewRequestEnabled || isAppointmentReminders || isReengagementsEnabled;

			if (integrationsExpected) {
				let crmIntegrations = await this.fetch(locationId, { status: ["active"] }, true);

				for (let i = 0; i < crmIntegrations.length; i++) {
					const crmIntegration = crmIntegrations[i];

					if (
						crmIntegration.type === CRM_INTEGRATIONS.type.athena_health.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.dr_chrono.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.mindbody.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.pteverywhere.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.salesforce.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.simplepractice.name ||
						crmIntegration.type === CRM_INTEGRATIONS.type.noterro.name
					) {
						let status = crmIntegration.meta_data && crmIntegration.meta_data.lastSessionCheckStatus;

						if (!status) {
							integrationsActive = false;
							break;
						}
					}

					if (crmIntegration.type === CRM_INTEGRATIONS.type.janeapp.name) {
						if (crmIntegration.meta_data && crmIntegration.meta_data.cookies) {
							let someJaneDevice = crmIntegration.meta_data.cookies.some(cookie => cookie.includes("jane_device"));

							if (!someJaneDevice) {
								integrationsActive = false;
								break;
							}
						} else {
							integrationsActive = false;
							break;
						}
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
		return {
			integrationsExpected,
			integrationsActive
		};
	},

	/**
	 * Fetches the config list of all integration apps avaliable
	 *
	 * @return {Promise}
	 */
	async fetchApps() {
		try {
			let response = await Kichiri.crm_integrations.fetchApps({}, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}

		return {};
	}
};

export default CrmIntegrationService;
